@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}


$blue: #2b77b7;
$black: #112233;

@font-face {
  font-family: Questrial;
  src: url(./assets/fonts/Questrial.otf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(./assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: Poppins-Medium;
  src: url(./assets/fonts/Poppins-Medium.ttf);
}

.poppins-regular {
  font-family: Poppins-Regular;
}

.poppins-semi-bold {
  font-family: Poppins-SemiBold;
}
.poppins-medium {
  font-family: Poppins-Medium;
}

.questrial {
  font-family: Questrial;
}

.bg-redaria-blue {
  background-color: $blue;
}

.bg-redaria-black {
  background-color: $black;
}

.border-redaria-blue {
  border-color: $blue;
  border-width: 1px;
}
.border-redaria-white {
  border-color: white;
  border-width: 1px;
}

.text-redaria-blue {
  color: $blue;
}

.title {
  @apply text-lg
}
.sub-title {
  @apply text-base
}

.text-redaria-black {
  color: $black;
}

.border-redaria-black {
  border-color: $black;
  border-width: 1px;
}

.border-b-redaria-black {
  border-color: $black;
}




.bg-facebook {
  background-color: #3A5897 !important;
}
.bg-google {
  background-color: #fff !important;
}
.bg-apple {
  background-color: #000 !important;
}

.redaria-button-blue:focus {
  @apply outline-none;
}


.redaria-button-social {
  font-size: 15px;
  height: 46px;
  @apply cursor-pointer bg-redaria-blue poppins-medium rounded-full text-white p-2 px-5 w-full shadow-md flex items-center;
}

.redaria-button-white {
  font-size: 15px;
  height: 46;
  @apply cursor-pointer bg-white poppins-medium rounded-full text-redaria-blue p-2 px-5 w-full shadow-md border-redaria-blue;
}

.redaria-button {
  font-size: 15px;
  height: 46px;
  @apply cursor-pointer poppins-medium rounded-full text-redaria-blue p-2 px-5 w-full;
}

.redaria-button-blue {
  font-size: 15px;
  height: 46px;
  @apply cursor-pointer bg-redaria-blue poppins-medium rounded-full text-white p-2 px-5 w-full shadow-md flex items-center justify-center;
}
.redaria-button-gray {
  font-size: 15px;
  height: 46px;
  @apply bg-gray-300 poppins-medium rounded-full text-white p-2 px-5 w-full shadow-md flex items-center justify-center;
}

.redaria-button-white:focus {
  @apply outline-none;
}


.redaria-input-container {
  @apply relative border-b-2 border-black focus-within:border-blue-500 mt-10 w-full;
}
.redaria-full-input-container {
  @apply relative border-b-2 border-black focus-within:border-blue-500 w-full;
}


.redaria-input {
  padding-bottom: 8px;
  font-size: 20px;
  @apply block w-full appearance-none focus:outline-none bg-transparent;
}
.redaria-textarea {
  @apply block w-full appearance-none focus:outline-none bg-transparent ;
}

.redaria-input-label {
  pointer-events:none;
  font-size: 20px;
  @apply absolute top-0  duration-300 origin-0 poppins-regular text-gray-300;
}

.redaria-input:focus-within ~ label,
.redaria-input:not(:placeholder-shown) ~ label {
  @apply transform scale-75 -translate-y-6;
}

.redaria-input:focus-within ~ label {
  @apply text-blue-500;
}

.deal-type-orange{
  background-color: #d67825;
}
.deal-type-pink{
  background-color: #7944a8;
}
.deal-type-blue{
  background-color: $blue;
}


.line-text {
  font-size: 23px;
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
  border-color: rgba(112, 112, 112, 0.21);
} 

.line-text span { 
   background:#fff; 
   padding:0 10px; 
}


.carousel-item{
	width: 100%;
	// height: 400px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.mobile-carousel-item{
	width: 100%;
	// height: 285px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.carousel-control-prev-icon{
	margin-left: -2px;
}

.carousel-control-next-icon{
	margin-right: -2px;
}

.carousel-indicators li{
  height: 7px !important;
  width: 7px !important;
  background-color: white !important;
  float: left !important;
  margin: 4px !important;
  border-radius: 50% !important;
  transition: transform 0.4s !important;
}
.carousel-indicators .active{
  transform: scale(1.6);
}



.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.link {
  @apply cursor-pointer;
}
.link:hover {
  color: $blue;
  border-bottom: $blue;
  border-bottom-width: 1px;
}

button:focus {
  outline: none;
}

.remove-background {
  background-color: transparent !important;
}

.bg-gray-new-property {
  background-color: #F8F8F8;
}


input[type="checkbox"] + label span {
  transition: background .2s,
    transform .2s;
}

.limit-text-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.react-confirm-alert-overlay {
  background: rgba(100, 107, 115, 0.6) !important;
}

.custom-ui {
  border-radius: 9px;
  background: white;
  padding: 20px;
}

.backgroun-mobile {
  background: transparent linear-gradient(150deg, rgba(43, 119, 183, 0.94) 0%, rgba(22, 60, 92, 0.94) 100%) 0% 0% no-repeat padding-box;
}

.child {
  flex: 1 1 auto;
}

.controls-panel {
  display: flex;
  flex-direction: column;
}

.controls {
  flex: 0 0 auto;
}

.content-wrapper {
  position: relative;
  flex: 1 1 auto;
  width: 500px;
}
.content-scroll {
  position: absolute;
  top: 0; left: 0;
  right: 0; bottom: 0;
  overflow-y: auto;
}
.content-item {
  display: inline-block;
  width: 100px;
  height: 100px;
}

.avatar {
  object-fit: cover;
}

.place{
  position: absolute; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  left: -10px;
  top: -10px;  
}

.tabLeft {
 @apply cursor-pointer poppins-medium rounded-l-md p-2 px-5 w-full shadow-md border-redaria-blue;
}

.tabRight {
 @apply cursor-pointer poppins-medium rounded-r-md p-2 px-5 w-full shadow-md border-redaria-blue;
}
